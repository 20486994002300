$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-title {
  text-align: center;
  color: rgba(35, 38, 47, 1);
  border-bottom: pxToVw(1) solid rgba(238, 238, 238, 1);

  .desc {
    font-size: pxToVw(12);
    color: rgba(119, 126, 144, 1);
    margin-top: pxToVw(129);
  }

  .h1 {
    font-size: pxToVw(40);
    margin: pxToVw(18) auto pxToVw(14);
  }

  .btn {
    display: inline-block;
    font-size: pxToVw(16);
    cursor: pointer;
    border-radius: pxToVw(100);
    padding: pxToVw(16) pxToVw(24);
    border: pxToVw(1) solid rgba(230, 232, 236, 1);
    margin-bottom: pxToVw(57);
  }
}
$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-header {
  width: 100%;
  height: pxToVw(80);
  box-shadow: 0px 1px 0px rgba(238, 238, 238, 1);

  .index-header-content {
    display: flex;
    align-items: center;
    width: pxToVw(1120);
    height: 100%;
    margin: 0 auto;

    .logo {
      margin-right: pxToVw(101);
  
      img {
        width: pxToVw(95);
      }
    }

    .nav {
      height: pxToVw(80);
      font-size: pxToVw(14);
      line-height: pxToVw(80);
      color: rgba(56, 56, 56, 1);
      margin-right: pxToVw(54);

      &:hover {
        font-weight: 500;
      }
    }
  }
}
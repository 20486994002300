$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-footer {
  height: pxToVw(471);
  background-color: rgba(245, 245, 245, 1);

  .index-footer-content {
    display: flex;
    width: pxToVw(1140);
    height: pxToVw(379);
    margin: 0 auto;

    .index-footer-content-left {
      flex: 0 0 pxToVw(808);
      width: pxToVw(808);

      .index-footer-content-left-logo {
        width: pxToVw(95);
        margin: pxToVw(69) 0 pxToVw(54);
      }

      .index-footer-content-left-title {
        font-size: pxToVw(24);
        font-weight: 500;
        color: rgba(56, 56, 56, 1);
        margin-bottom: pxToVw(25);
      }

      .index-footer-content-left-desc {
        width: pxToVw(289);
        font-size: pxToVw(20);
        line-height: pxToVw(40);
        color: rgba(56, 56, 56, 1);
      }
    }

    .index-footer-content-right {
      flex: 0 0 pxToVw(472);
      width: pxToVw(472);
      font-size: pxToVw(14);
      color: rgba(56, 56, 56, 1);

      .index-footer-content-right-erweima {
        display: flex;
        width: pxToVw(90);
        text-align: center;
        font-size: pxToVw(14);
        margin: pxToVw(100) 0 pxToVw(56);

        .erweima-item {
          width: pxToVw(77);
          margin-right: pxToVw(45);

          .erweima-item-img {
            width: pxToVw(77);
            height: pxToVw(77);
            margin-bottom: pxToVw(7);
          }

          .erweima-item-text {
            font-size: pxToVw(12);
            color: rgba(56, 56, 56, 1);
          }
        }
      }

      .index-footer-content-right-contact {
        margin-bottom: pxToVw(26);
      }
    }
  }

  .index-footer-copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: pxToVw(92);
    font-size: pxToVw(12);
    font-weight: 500;
    color: rgba(166, 166, 166, 1);
    border-top: pxToVw(1) solid rgba(238, 238, 238, 1);
    margin: 0 pxToVw(161);

    a {
      color: rgba(166, 166, 166, 1);
    }
  }
}
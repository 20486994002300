$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-authors {
  background-color: rgba(244, 245, 246, 1);

  .index-authors-container {
    position: relative;
    width: pxToVw(1120);
    margin: 0 auto;

    .arr_l {
      position: absolute;
      left: pxToVw(-72);
      top: pxToVw(328);
      width: pxToVw(40);
      height: pxToVw(40);
      cursor: pointer;
    }

    .arr_r {
      position: absolute;
      right: pxToVw(-72);
      top: pxToVw(328);
      width: pxToVw(40);
      height: pxToVw(40);
      cursor: pointer;
    }

    .title {
      font-size: pxToVw(32);
      font-weight: 500;
      color: rgba(56, 56, 56, 1);
      padding: pxToVw(104) 0 pxToVw(64);
    }

    .list_container {
      width: pxToVw(1120);
      overflow: hidden;
    }

    .list {
      position: relative;
      left: 0;
      top: 0;
      transition: left 1s;
      display: grid;
      width: pxToVw(1118);
      grid-template-columns: repeat(12, pxToVw(198));
      column-gap: pxToVw(32);
      padding-bottom: pxToVw(174);
  
      .author {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: pxToVw(247);
        padding: pxToVw(24);
        color: rgba(35, 38, 47, 1);
        font-weight: 500;
        box-sizing: border-box;
        background-color: rgba(252, 252, 253, 1);
        border-radius: pxToVw(16);
  
        .author-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          color: #fff;
          font-size: pxToVw(12);
          font-weight: 500;
          padding-bottom: pxToVw(24);
          border-bottom: pxToVw(1) solid rgba(230, 232, 236, 1);

          .author-title-status {
            display: flex;
            align-items: center;
            padding: pxToVw(2) pxToVw(10);
            border-radius: pxToVw(90);

            .author-title-status_icon {
              width: pxToVw(16);
              height: pxToVw(16);
              margin-right: pxToVw(5);
            }

            .author-title-status_line {
              padding: 0 pxToVw(5);
            }
          }

          .author-title-arrow {
            width: pxToVw(24);
            height: pxToVw(24);
          }
        }

        .author-avator {
          width: pxToVw(62);
          height: pxToVw(62);
          margin: pxToVw(20) auto pxToVw(22);
        }

        .author-name {
          font-size: pxToVw(16);
        }

        .author-name-sub {
          font-size: pxToVw(14);
        }
      }
    }

    .list_1 {
      left: pxToVw(-1150);
    }

    .list_2 {
      left: pxToVw(-2300);
    }
  }
}
$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index {
  width: 100vw;
  min-height: 100vh;
  background-color: #ffffff;

  .container {
    width: pxToVw(1120);
    margin: 0 auto;
  }
}
.explore-modal {
  position: relative;
  height: pxToVw(383);
  padding-top: pxToVw(32);
  box-sizing: border-box;
  color: rgba(56, 56, 56, 1);

  .explore-modal-icon {
    position: absolute;
    right: pxToVw(30);
    top: pxToVw(30);
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToVw(40);
    height: pxToVw(40);
    font-size: pxToVw(18);
    border-radius: pxToVw(20);
    border: pxToVw(1) solid rgba(166, 166, 166, 1);
  }

  .explore-modal-title {
    font-size: pxToVw(26);
    padding-left: pxToVw(40);
  }

  .explore-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: pxToVw(16);

    .explore-modal-content-img {
      width: pxToVw(154);
      height: pxToVw(154);
      margin: pxToVw(52) auto pxToVw(28);
    }
  }
}
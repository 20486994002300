$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-board {
  position: relative;
  height: pxToVw(615);
  padding: pxToVw(195) pxToVw(160) pxToVw(188) pxToVw(398);
  box-sizing: border-box;

  .index-board-content {
    width: pxToVw(882);
    height: pxToVw(220);
    box-sizing: border-box;
    padding: pxToVw(37) pxToVw(105) 0 pxToVw(235);
    box-shadow: 0 pxToVw(5) pxToVw(20) rgba(0, 0, 0, 0.1);
    border-radius: pxToVw(16);

    .index-board-content-title {
      font-style: pxToVw(16);
      margin-bottom: pxToVw(18);
      color: rgba(56, 56, 56, 1);
    }

    .index-board-content-desc,
    .index-board-content-time {
      white-space: pre-wrap;
      font-size: pxToVw(12);
      line-height: pxToVw(20);
      color: rgba(128, 128, 128, 1);
    }

    .index-board-content-desc {
      width: pxToVw(544);
      margin-bottom: pxToVw(16);
    }
  }

  .index-board-pic {
    position: absolute;
    left: pxToVw(133);
    top: pxToVw(65);
    width: pxToVw(475);
    height: pxToVw(475);
  }
}
$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-aboutus {
  padding-top: pxToVw(100);
  background-color: rgba(244, 245, 246, 1);

  .index-aboutus-title {
    text-align: center;
    font-size: pxToVw(32);
    font-weight: 500;
    color: rgba(56, 56, 56, 1);
    margin-bottom: pxToVw(8);
  }

  .index-aboutus-subtitle {
    text-align: center;
    font-size: pxToVw(16);
    font-weight: 500;
    color: rgba(128, 128, 128, 1);
    margin-bottom: pxToVw(48);
  }

  .index-aboutus-list {
    display: flex;
    justify-content: space-between;
    padding: 0 pxToVw(167) pxToVw(147);

    .index-aboutus-list-item {
      width: pxToVw(305);
      height: pxToVw(266);
      padding: pxToVw(35);
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.12);
      border-radius: pxToVw(16);
    }

    .index-aboutus-list-item-title {
      text-align: center;
      font-size: pxToVw(16);
      font-weight: 500;
      color: rgba(56, 56, 56, 1);
      margin: 0 auto pxToVw(24);
    }

    .index-aboutus-list-item-desc {
      line-height: pxToVw(30);
      font-size: pxToVw(14);
      color: rgba(119, 126, 144, 1);
    }
  }
}

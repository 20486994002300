$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-banner {
  display: flex;
  justify-content: space-between;
  padding-top: pxToVw(79);

  .index-banner-left {
    width: pxToVw(640);
    height: pxToVw(429);
    background-color: #000;
    border-radius: pxToVw(16);
    margin: pxToVw(21) pxToVw(96) pxToVw(130) 0;
  }

  .index-banner-right {

    img {
      width: pxToVw(384);
      height: pxToVw(190);
    }

    .index-banner-right-subtitle {
      width: pxToVw(384);
      height: pxToVw(177);
      line-height: pxToVw(22);
      font-size: pxToVw(12);
      color: rgba(141, 149, 168, 1);
      margin: pxToVw(24) 0 pxToVw(11);

      p {
        text-indent: 2em;
        margin-bottom: 0;
      }
    }

    .index-banner-right-btn {
      width: pxToVw(352);
      height: pxToVw(48);
      line-height: pxToVw(48);
      font-size: pxToVw(16);
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      border-radius: pxToVw(90);
      background: linear-gradient(236.31deg, rgba(87, 228, 255, 1) 0%, rgba(138, 148, 242, 1) 26.03%, rgba(139, 114, 238, 1) 44.83%, rgba(133, 86, 234, 1) 60.47%, rgba(65, 56, 229, 1) 100%);;
    }
  }
}
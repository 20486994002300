$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-goods {
  .title {
    font-size: pxToVw(32);
    font-weight: 500;
    color: rgba(56, 56, 56, 1);
    margin-bottom: pxToVw(48);
  }

  .list {
    display: grid;
    grid-template-columns: repeat(4, pxToVw(256));
    gap: pxToVw(58) pxToVw(32);
    margin-bottom: pxToVw(40);

    .card {
      .card-img {
        width: pxToVw(256);
        height: pxToVw(310);
        cursor: pointer;
      }

      .card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(56, 56, 56, 1);
        font-size: pxToVw(16);
        padding: pxToVw(16) pxToVw(8);

        .card-title-erweima {
          width: pxToVw(16);
          height: pxToVw(16);
          cursor: pointer;
        }
      }

      .card-num {
        display: inline-flex;
        padding: pxToVw(1);
        border-radius: pxToVw(40);
        background: linear-gradient(90deg, rgba(59, 121, 255, 1) 0%, rgba(188, 106, 230, 1) 100% );
        margin-bottom: pxToVw(16);
        overflow: hidden;

        .card-num-price {
          color: #fff;
          padding: pxToVw(4) pxToVw(11) pxToVw(4) pxToVw(14);
          background: #000;
          background: linear-gradient(90deg, rgba(59, 121, 255, 1) 0%, rgba(188, 106, 230, 1) 100%);
        }

        .card-num-cnt {
          padding: pxToVw(4) pxToVw(14) pxToVw(4) pxToVw(7);
          background-color: #fff;
          border-radius: 0 pxToVw(40) pxToVw(40) 0;
        }
      }

      .card-author {
        display: flex;
        align-items: center;
        padding-top: pxToVw(16);
        border-top: pxToVw(1) solid rgba(238, 238, 238, 1);

        .card-author-avatar {
          width: pxToVw(24);
          height: pxToVw(24);
          border-radius: pxToVw(24);
          margin-right: pxToVw(6);
          overflow: hidden;
        }

        .card-author-name {
          font-size: pxToVw(14);
          color: rgba(56, 56, 56, 1);
        }
      }
    }
  }
}
$mediaWidth: 1440;
@function pxToVw ($px) {
  @return  calc(($px * 100 / $mediaWidth) * 1vw);
;
}

.index-energize {
  position: relative;
  height: pxToVw(879);
  padding: pxToVw(213) pxToVw(205) pxToVw(378);
  box-sizing: border-box;

  .index-energize-content {

    .index-energize-content-title {
      color: rgba(56, 56, 56, 1);
      font-weight: 500;
      font-size: pxToVw(32);
      margin-bottom: pxToVw(47);
    }

    .index-energize-content-desc {
      white-space: pre-wrap;
      font-size: pxToVw(14);
      line-height: pxToVw(30);
      color: rgba(119, 126, 144, 1);
    }

    .index-energize-content-desc {
      width: pxToVw(480);
    }
  }

  .index-energize-pic {
    position: absolute;
    right: pxToVw(97);
    top: pxToVw(58);
    width: pxToVw(654);
    height: pxToVw(665);
  }
}